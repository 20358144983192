import AsyncComponent from '../components/AsyncComponent';

const createRoutes: any = [
    {
        path: '/report',
        component: AsyncComponent(() => import('../view/report/report')),
        exact: true
    },
    {
        path: '/details',
        component: AsyncComponent(() => import('../view/details/details')),
        exact: true
    },
    {
        path: '/agreement',
        component: AsyncComponent(() => import('../view/agreement/agreement')),
        exact: true
    }
];

export { createRoutes };
